import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-033b01e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    exact: _ctx.exact,
    class: "element",
    "active-class": "active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.iconClasses),
        style: _normalizeStyle(_ctx.iconStyle)
      }, null, 6),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
        _withDirectives(_createElementVNode("span", { class: "badge" }, _toDisplayString(_ctx.badge), 513), [
          [_vShow, _ctx.badge !== null]
        ])
      ], 512), [
        [_vShow, !_ctx.isCollapsed]
      ])
    ]),
    _: 1
  }, 8, ["to", "exact"]))
}