import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-858ac108"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authDetermined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.containerClass)
        }, [
          (_ctx.isAuthenticated && _ctx.shouldShowSidebar)
            ? (_openBlock(), _createBlock(_component_SidebarComponent, {
                key: 0,
                onSidebarToggled: _ctx.handleSidebarToggled,
                onSidebarTransitioned: _ctx.handleSidebarTransitioned
              }, null, 8, ["onSidebarToggled", "onSidebarTransitioned"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.mainContentClass)
          }, [
            (_ctx.isAuthenticated && _ctx.shouldShowSidebar)
              ? (_openBlock(), _createBlock(_component_AppHeader, {
                  key: 0,
                  title: _ctx.pageTitle,
                  "breadcrumb-text": _ctx.breadcrumbText,
                  "user-initials": _ctx.userInitials
                }, null, 8, ["title", "breadcrumb-text", "user-initials"]))
              : _createCommentVNode("", true),
            _createVNode(_component_router_view)
          ], 2)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}