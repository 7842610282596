import { UserState } from "@/store/modules/userStore";

export const rolePermissions: Record<string, (keyof UserState["roles"])[]> = {
  home: ["isStaff", "isQc", "isTester", "isAdmin"],
  clients: ["isStaff", "isAdmin"],
  testresults: ["isStaff", "isQc", "isTester", "isAdmin"],
  analytics: ["isStaff", "isQc", "isTester", "isAdmin"],
  forecast: ["isStaff", "isQc", "isTester", "isAdmin"],
  micronstore: ["isAdmin", "isStaff"],
  learninghub: ["isAdmin", "isStaff"],
  support: ["isStaff", "isAdmin"],
  settings: ["isStaff", "isQc", "isAdmin"],
  account: ["isStaff", "isQc", "isTester", "isAdmin"],
  logout: ["isStaff", "isQc", "isTester", "isAdmin"],
};
