import { useAuthStore } from "@store/modules/authStore";
import axios, { AxiosRequestConfig } from "axios";
import { Comment, TestResultDetails } from "@/types/tests/TestResultDetails";

const baseURL = process.env.VUE_APP_BACKEND_URL || "http://192.168.1.4:3000/api/v2";

const apiInstance = axios.create({
  baseURL: baseURL,
});

apiInstance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    if (authStore.token) {
      config.headers.Authorization = `Bearer ${authStore.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

function getStoredLanguage(): string {
  return localStorage.getItem("user-locale") || "en";
}

async function localisedPost<T = any>(url: string, data: any, config: AxiosRequestConfig = {}): Promise<T> {
  const languageHeader = {
    headers: {
      "X-App-Language": getStoredLanguage(),
      ...config.headers,
    },
  };
  return apiInstance.post(url, data, { ...config, ...languageHeader });
}

export default apiInstance;

export interface TestData {
  processing: number;
  positive: number;
  negative: number;
  total: number;
}

export interface AnimalTypeData {
  type: string;
  count: number;
}

export interface ClientLatestTests {
  sampleId: string;
  testType: string;
  animalType: string;
  date: string;
  status: string;
}

export interface TestWeekData {
  week: string;
  count: number;
}

export interface BadgeData {
  home: number | null;
  clients: number | null;
  testresults: number | null;
  users: number | null;
  micronstore: number | null;
  learninghub: number | null;
  support: number | null;
  settings: number | null;
  account: number | null;
  logout: number | null;
  [key: string]: number | null;
}

export interface DateRange {
  start: string;
  end: string;
}

export interface TrendData {
  date: string;
  count: number;
}

export interface EggCountData {
  dates: string[];
  eggCounts: Record<string, number[]>;
}

export interface Client {
  id: number;
  name: string;
  clientNumber: string;
  address: string;
  testsCompleted: number;
  animalType: string;
  farmType: string;
  herdNumber: string;
  lastTestDate: string;
  lat?: number;
  lng?: number;
}

export interface ClientForm {
  name: string;
  country: string;
  postcode: string;
  address: string;
  herdNumber: string;
  clientType: string;
}
export interface ClientDetails {
  id: number;
  name: string;
  clientNumber: string;
  country: string;
  postcode: string;
  address: string;
  herdNumber: string;
  clientType: string;
}

export interface ClientTestSummary {
  total: number;
  analysing: number;
  positive: number;
  negative: number;
}

export interface ClientMapData {
  lat: number;
  lng: number;
  clientId: string;
}

export interface TestCounts {
  totalTests: number;
  analysingTests: number;
  positiveTests: number;
  negativeTests: number;
}

export interface TestResultResponse {
  data: TestResultRaw[];
  totalRecords: number;
  counts: TestCounts;
}

export interface TestResultRaw {
  id: number;
  uuid: string;
  cowId: string | null;
  clientName: string | null;
  clientId: string | null;
  testType: number | string;
  animalType: number | string;
  createdAt: string;
  status: string;
}

export interface BusinessStats {
  testsPrevious30Days: number;
  testsLast30Days: number;
  inactiveClients: number;
}

export interface UserData {
  id: number;
  email: string;
  name: string;
  avatar: string;
  is_staff: boolean;
  is_qc: boolean;
  is_tester: boolean;
  is_admin: boolean;
  availableTest: number;
  playerId: string | null;
}

export interface BusinessData {
  id: number;
  name: string;
  logo: string | null;
  credits: number;
  expirydate: string;
  role: number;
  infinite_credits: boolean;
  fluke_kit: boolean;
  flukekitnumber: string | null;
  activationdate: string;
}

export interface TestResult {
  id: number;
  testId: string;
  sampleId: string;
  clientName: string;
  testType: string;
  clientId: string;
  animalType: string;
  farmType: string;
  flockNumber: string;
  createdAt: number;
  status: string;
  isDownloadable: boolean;
}

export interface TestsMapData {
  lat: number;
  lng: number;
  name: string;
  color: string;
  location: string;
  tests: number;
  date: string;
  status: string;
}

// Analytics API calls
export async function fetchStatusAllData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<any> {
  const response = await apiInstance.post(`/admin/analytics/graphs`, {
    chartType: "status-all",
    dateRange: dateRange,
    country: country,
    filters: filters,
  });
  return response.data.data;
}

export async function fetchCountryData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<any> {
  const response = await apiInstance.post(`/admin/analytics/graphs`, {
    chartType: "country",
    dateRange: dateRange,
    country: country,
    filters: filters,
  });
  return response.data.data;
}

export async function fetchRegionData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<any> {
  const response = await apiInstance.post(`/admin/analytics/graphs`, {
    chartType: "region",
    dateRange: dateRange,
    country: country,
    filters: filters,
  });
  return response.data.data;
}

export async function fetchAnimalTypeData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<any> {
  const response = await apiInstance.post(`/admin/analytics/graphs`, {
    chartType: "animal-type",
    dateRange: dateRange,
    country: country,
    filters: filters,
  });
  return response.data.data;
}

export async function fetchFecVsFlukeData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<any> {
  const response = await apiInstance.post(`/admin/analytics/graphs`, {
    chartType: "fec-vs-fluke",
    dateRange: dateRange,
    country: country,
    filters: filters,
  });
  return response.data.data;
}

export async function fetchTotalTestsData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<any> {
  const response = await apiInstance.post(`/admin/analytics/graphs`, {
    chartType: "total-tests",
    dateRange: dateRange,
    country: country,
    filters: filters,
  });
  return response.data.data;
}

export async function fetchNumberOfTestsData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
): Promise<{ week: string; count: number }[]> {
  try {
    const response = await apiInstance.post("/admin/analytics/graphs/liveTracker/", {
      chartType: "total-tests",
      dateRange,
      country,
      filters,
    });

    // Ensure the service returns an array of objects with the correct structure
    return response.data.data.map((item: { week: string; count: number }) => ({
      week: item.week,
      count: item.count,
    }));
  } catch (error) {
    console.error("Error fetching number of tests data:", error);
    return [];
  }
}

export async function fetchBurdenLevelData(
  dateRange?: DateRange,
  country?: string,
  filters?: Record<string, string[]>,
  chartType?: string,
): Promise<number[]> {
  try {
    const response = await apiInstance.post("/admin/analytics/graphs/liveTracker/", {
      chartType,
      dateRange,
      country,
      filters,
    });

    return response.data.data;
  } catch (error) {
    console.error("Error fetching number of tests data:", error);
    return [];
  }
}

// Home API calls
export async function fetchBadgeData(): Promise<BadgeData> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        home: null,
        clients: null,
        testresults: 6,
        users: null,
        micronstore: null,
        learninghub: 3,
        support: null,
        settings: null,
        account: null,
        logout: null,
      });
    }, 1000);
  });
}

export async function fetchCredits(): Promise<number> {
  const response = await apiInstance.get(`/admin/credits`);
  return response.data.credits;
}

export async function fetchExpiryDate(): Promise<string> {
  const response = await apiInstance.get(`/admin/expiry-date`);
  return response.data.expiryDate;
}

export async function fetchUserData(): Promise<UserData> {
  const response = await apiInstance.get("/users/information");
  return response.data.data;
}

export async function fetchBusinessList(): Promise<BusinessData[]> {
  const response = await apiInstance.get("/businesses/list");
  return response.data.data;
}

export async function login(credentials: { email: string; password: string }): Promise<{ token: string }> {
  const response = await apiInstance.post("/users/login", credentials);
  return response.data;
}

export async function requestPasswordReset(params: { email: string }): Promise<string> {
  const response = await apiInstance.post("/users/forgotpassword/", params);
  return response.data;
}

export async function resetPassword(params: { token: string; newPassword: string }): Promise<string> {
  const data = { key: params.token, confirm_password: params.newPassword, password: params.newPassword };
  const response = await apiInstance.put("/users/recovery/changepassword/", data);
  return response.data;
}

export async function fetchBusinessStats(businessId: number): Promise<BusinessStats> {
  try {
    const response = await apiInstance.get(`/businesses/${businessId}/montly-stats/`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching business stats:", error);
    throw error;
  }
}

export async function fetchTestsData(params: { startDate?: string; endDate?: string }): Promise<TestData> {
  try {
    const response = await apiInstance.post("/admin/analytics/tests/summary/", {
      startDate: params.startDate,
      endDate: params.endDate,
    });
    const testData: TestData = {
      processing: 0,
      positive: 0,
      negative: 0,
      total: 0,
    };
    response.data.data.forEach((item: any) => {
      switch (item.status) {
        case "process":
          testData.processing = parseInt(item.count, 10);
          break;
        case "green":
          testData.negative = parseInt(item.count, 10);
          break;
        case "red":
          testData.positive = parseInt(item.count, 10);
          break;
      }
    });
    testData.total = testData.processing + testData.negative + testData.positive;
    return testData;
  } catch (error) {
    console.error("Error fetching test data:", error);
    throw error;
  }
}

export async function fetchTrendDataWithParams(params: {
  filters?: string;
  startDate?: string;
  endDate?: string;
}): Promise<TrendData[]> {
  try {
    const response = await apiInstance.post("/admin/analytics/trends", {
      filters: params.filters,
      startDate: params.startDate,
      endDate: params.endDate,
    });

    return response.data.data;
  } catch (error) {
    console.error("Error fetching trend data with filters:", error);
    return [];
  }
}

export async function fetchTestMapData(params: {
  outcomes?: string[];
  startDate?: string;
  endDate?: string;
}): Promise<TestsMapData[]> {
  try {
    const response = await localisedPost("/admin/analytics/client-map", params);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching client map data:", error);
    return [];
  }
}

export async function fetchEggCountData(params: {
  filters?: string;
  eggTypes?: string[];
  startDate?: string;
  endDate?: string;
}): Promise<EggCountData> {
  try {
    const response = await apiInstance.post("/admin/analytics/egg-count", params);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching egg count data:", error);
    throw error;
  }
}

// Client API calls
export async function fetchClients(businessId: number, params: { search?: string }): Promise<Client[]> {
  try {
    const response = await apiInstance.get(`/businesses/${businessId}/dashboard/clients/`, {
      params,
    });
    // Mock lat and lng for demo purposes
    const clients = response.data.data
      .slice(0, 100) // Limit to the first 100 clients
      .map((client: any) => ({
        ...client,
        lat: client.lat,
        lng: client.lng,
      }));

    return clients;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
}

export async function createClient(businessId: number, clientData: ClientForm): Promise<void> {
  try {
    const response = await apiInstance.post(`/businesses/${businessId}/dashboard/clients/`, clientData);
    return response.data;
  } catch (error) {
    console.error("Error creating client:", error);
    throw error;
  }
}

export async function fetchClientDetails(businessId: number, clientId: number): Promise<ClientDetails> {
  try {
    const response = await apiInstance.get(`/businesses/${businessId}/dashboard/clients/${clientId}/`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching client details:", error);
    throw error;
  }
}

export async function updateClientDetails(
  businessId: number,
  clientId: number,
  clientData: Partial<ClientDetails>,
): Promise<void> {
  try {
    await apiInstance.put(`/businesses/${businessId}/dashboard/clients/${clientId}/`, clientData);
  } catch (error) {
    console.error("Error updating client details:", error);
    throw error;
  }
}

export async function importClients(businessId: number, file: File): Promise<void> {
  try {
    const formData = new FormData();
    formData.append("file", file);
    await apiInstance.post(`/businesses/${businessId}/dashboard/clients/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("Error importing clients:", error);
    throw error;
  }
}

export async function fetchClientAnimalTypeData(params: { clientId: number }): Promise<AnimalTypeData[]> {
  try {
    const response = await apiInstance.post("/admin/analytics/client/animal-tests", params);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching animal type data:", error);
    throw error;
  }
}

export async function fetchClientLatestTests(
  businessId: number,
  clientId: number,
): Promise<ClientLatestTests[]> {
  try {
    const response = await apiInstance.get(
      `/businesses/${businessId}/dashboard/clients/${clientId}/latest-tests`,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching latest tests:", error);
    throw error;
  }
}

export async function fetchClientTestSummary(
  businessId: number,
  clientId: number,
): Promise<{ data: ClientTestSummary }> {
  try {
    const response = await apiInstance.get(
      `/businesses/${businessId}/dashboard/clients/${clientId}/test-summary`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching summary data:", error);
    throw error;
  }
}

// Tests API calls
export async function fetchTableTestsData(params: {
  page: number;
  limit: number;
  search?: string;
  sortKey?: string;
  sortOrder?: string;
  filters?: any;
}): Promise<TestResultResponse> {
  try {
    const offset = (params.page - 1) * params.limit;
    const mapped_sortKey = params.sortKey === "createdAt" ? "created_at" : params.sortKey;

    const response = await apiInstance.post(`/tests/results/${offset}/${params.limit}`, {
      search: params.search || "",
      sortField: mapped_sortKey || "created_at",
      sortOrder: params.sortOrder || "descending",
      filters: params.filters || {},
    });

    const { data, totalRecords, counts } = response.data;
    return {
      data: data as TestResultRaw[],
      totalRecords: totalRecords,
      counts: {
        totalTests: totalRecords || 0,
        analysingTests: counts.analysingTests || 0,
        positiveTests: counts.positiveTests || 0,
        negativeTests: counts.negativeTests || 0,
      },
    };
  } catch (error) {
    console.error("Error fetching test data:", error);
    throw new Error("Failed to fetch test data");
  }
}

export async function downloadExportAll(params: { filters?: any; search?: string }): Promise<void> {
  try {
    const response = await apiInstance.post(
      "/tests/download/export-all",
      {
        search: params.search || "",
        filters: params.filters || {},
      },
      {
        responseType: "blob",
      },
    );
    const fileName = response.headers["content-disposition"]
      ? response.headers["content-disposition"].split("filename=")[1]
      : "export.zip";

    const blob = new Blob([response.data], { type: "application/zip" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading export-all ZIP file:", error);
  }
}

export async function fetchTestDetails(id: number, type?: string): Promise<TestResultDetails> {
  try {
    const response = await apiInstance.get(`/tests/details/${id}/${type || ""}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching test details:", error);
    throw error;
  }
}

export async function addCommentToTest(testId: number, comment: Comment, type?: string): Promise<Comment> {
  try {
    const response = await apiInstance.post(`/tests/details/comment/${testId}/${type || ""}`, comment);
    return response.data.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
}

export async function updateTestComment(updatedComment: Comment, type?: string): Promise<Comment> {
  try {
    const response = await apiInstance.patch(`/tests/details/comment/${updatedComment.id}/${type || ""}`, {
      text: updatedComment.text,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
}

export async function fetchPdfReport(testId: number): Promise<string> {
  try {
    const response = await apiInstance.get(`/admin/download/fec/${testId}`);
    return response.data.html;
  } catch (error) {
    console.error("Error fetching PDF report:", error);
    throw error;
  }
}

export async function importResults(businessId: number, file: File): Promise<void> {
  try {
    const formData = new FormData();
    formData.append("file", file);
    await apiInstance.post(`/tests/import/${businessId}/results`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("Error importing clients:", error);
    throw error;
  }
}
